import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClient, HttpClientModule } from '@angular/common/http';


import { AppComponent } from './app.component';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app.routes';
import { RouterModule, RouterOutlet } from '@angular/router';
import { SpinnerComponent } from './pages/shared/spinner/spinner.component';
import PaymentComponent from './components/payment/payment.component';
import { TableModule } from 'primeng/table';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    SpinnerComponent, 
    PaymentComponent,
    
    RouterOutlet,
    TableModule,
    NgxDaterangepickerMd.forRoot()
   
    
  ],
  providers: [DialogService,MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
