// models/bill.model.ts
import { BillDetail } from "./bill-detail.model";
import { Payment } from "./payment.model";

export class Bill {
    constructor(
      public id: string,
      public warehouseId: string,
      public accountId: string,
      public period: string,
      public description: string,
      public amount_due: number, 
      public tax_amount: number, 
      public discount: number,
      public subtotal: number, 
      public currency: string,
      public dueDate: Date,
      public status: string,
      public createdAt: Date,
      public updatedAt: Date,
      public details: BillDetail[],
      public payments: Payment[]
    ) {}
  }
  