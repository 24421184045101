// src/app/services/stripe/stripe.service.ts

import { Injectable } from '@angular/core';
import { loadStripe, Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripePromise: Promise<Stripe | null> = loadStripe('pk_test_51PEdO702qyqYpMu2faAcTC7mKZE9xgAuWRZ9j0lMJ4HGnA60M8EckOlnwaCnM20gIX1gB8YdafSHoUsllv9x6acx00HsS0LdHn');

  async getElements(): Promise<StripeElements | null> {
    const stripe = await this.stripePromise;
    return stripe ? stripe.elements() : null;
  }

  async createToken(card: StripeCardElement): Promise<{ token?: any, error?: any }> {
    const stripe = await this.stripePromise;
    if (!stripe) {
      throw new Error("Stripe is not initialized");
    }
    const result = await stripe.createToken(card);
    if (result.error) {
      return { error: result.error };
    } else {
      return { token: result.token };
    }
  }
}
