<div class="fixed inset-0 z-50 flex justify-center items-center" *ngIf="isLoading">
    <div role="status" class="flex items-center">
      <img src="assets/images/buho-black.png" alt="Logo Cargando" class="h-16 dark:hidden mr-4">
      <img src="assets/images/buho-white.png" alt="Logo Cargando" class="h-16 hidden dark:block mr-4">
      <svg class="animate-spin ml-2 mr-3 h-5 w-5 text-gray-900 dark:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span class="text-lg font-bold dark:text-white text-black">Cargando ...</span>
    </div>
  </div>
  