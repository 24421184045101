// auth.guard.ts
import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.checkStatus().pipe(
    map((authenticated) => {
      if (
        authenticated &&
        authService.hasPermission('dashboard.acceder_dashboard')
      ) {
        return true;
      } else {
        router.navigateByUrl('/no-access');
        return false;
      }
    }),
    catchError(() => {
      router.navigateByUrl('/login');
      return of(false);
    })
  );
};
