// app.component.ts

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { ThemeService } from './services/theme/theme.service';
import { SpinnerComponent } from "./pages/shared/spinner/spinner.component";
import { ModalService } from './services/modal/modal.service';
import PaymentComponent from './components/payment/payment.component';  // Asegúrate de tener la ruta correcta
import { AuthService } from './services/auth/auth.service';




@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [CommonModule, RouterModule, RouterOutlet, HttpClientModule, SpinnerComponent, PaymentComponent ]
})
export class AppComponent {
  title = 'bottletracking-dashboard';

  constructor(

    private authService: AuthService,
    public modalService: ModalService,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    initFlowbite();
    this.themeService.initializeTheme();

    // Verificar si pendingPayment está establecido como 'true' en localStorage
    // if (localStorage.getItem('pendingPayment') === 'true') {
    //   this.modalService.openModal('paymentModal');
    // }
  }
}