export class Payment {
  constructor(
    public id: number,
    public bill_id: number,
    public account_id: string | null,
    public warehouse_id: string | null,
    public payment_platform_id: string,
    public description: string,
    public amount: number,
    public status: string,
    public transaction_id: string,
    public payment_response: string,
    public created_at: Date,
    public updated_at: Date
  ) {}
}
