// spinner.component.ts

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';
import { CommonModule } from '@angular/common';
 

@Component({
  selector: 'app-spinner',
  standalone:true,
  templateUrl: './spinner.component.html',
  imports: [CommonModule],
  styles: ``
})
export class SpinnerComponent implements OnDestroy {
  isLoading: boolean = false;
  private subscription: Subscription;

  constructor(private loadingService: LoadingService) {
    this.subscription = this.loadingService.loading$.subscribe((status: boolean) => {
      this.isLoading = status;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
