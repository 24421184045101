import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPostfix',
  standalone: true
})
export class CurrencyPostfixPipe implements PipeTransform {
  transform(value: number, currencyCode: string = 'MXN', locale: string = 'en'): string {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2, // Asegura dos dígitos decimales
      maximumFractionDigits: 2  // Asegura dos dígitos decimales
    });

    // Formatea el valor y luego elimina el símbolo de la moneda
    let formattedValue = formatter.format(value);
    formattedValue = formattedValue.replace(/[^\d.,]/g, '');  // Remueve no dígitos excepto comas y puntos

    return '$' + ' '+ formattedValue + ' ' + currencyCode;
  }
}

export default CurrencyPostfixPipe;