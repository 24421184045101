// models/bill-detail.model.ts
export class BillDetail {
  constructor(
    public id: number,
    public billId: number,
    public description: string,
    public amount: number,
    public tax: number,
    public total: number,
    public createdAt: Date,
    public updatedAt: Date
  ) {}
}
