import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: 'dashboard',
    loadComponent: () => import('./pages/dashboard/dashboard.component'),
    children: [
      {
        path: 'monitor',
        title: 'Monitor View',
        data: {
          pageTitle: 'Monitor',
          permissions: ['dashboard.acceder_dashboard'],
        },
        loadComponent: () => import('./pages/monitor/monitor.component'),
        canActivate: [authGuard],
      },

      {
        path: 'bottles',
        title: 'Bottles View',
        data: {
          pageTitle: 'Bottles',
          permissions: ['botellas.acceder_botellas'],
        },
        loadComponent: () => import('./pages/bottles/bottles.component'),
        canActivate: [authGuard],
      },
      {
        path: 'orders',
        title: 'Orders View',
        data: {
          pageTitle: 'Órdenes',
          permissions: ['ordenes.acceder_ordenes'],
        },
        loadComponent: () => import('./pages/orders/orders.component'),
        canActivate: [authGuard],
      },
      {
        path: 'sessions',
        title: 'Sessions View',
        data: {
          pageTitle: 'Sesiones',
          permissions: ['sesiones.acceder_sessiones'],
        },
        loadComponent: () =>
          import('./pages/sessions/sessions.component').then(
            (m) => m.SessionsComponent
          ),
        canActivate: [authGuard],
      },

      {
        path: 'inventories',
        title: 'Inventories View',
        data: {
          pageTitle: 'Inventarios',
          permissions: ['desplazamientos.acceder_desplazamientos'],
        },
        loadComponent: () =>
          import('./pages/inventories/inventories.component'),
        canActivate: [authGuard],
      },

      {
        path: 'displacements',
        title: 'Displacements View',
        data: {
          pageTitle: 'Desplazamientos',
          permissions: ['desplazamientos.acceder_desplazamientos'],
        },
        loadComponent: () =>
          import('./pages/displacements/displacements.component'),
        canActivate: [authGuard],
      },
      {
        path: 'user/:id',
        title: 'User View',
        data: { pageTitle: 'User' },
        loadComponent: () => import('./pages/user/user.component'),

        canActivate: [authGuard],
      },
      {
        path: 'roles',
        title: 'Roles y Permisos',
        data: {
          pageTitle: 'Roles y Permisos',
          permissions: ['inventarios.acceder_inventarios'],
        },
        loadComponent: () => import('./pages/managment/roles/roles.component'),
        canActivate: [authGuard],
      },
      {
        path: 'users',
        title: 'User List',
        data: {
          pageTitle: 'Usuarios',
          permissions: ['usuarios.acceder_usuarios'],
        },
        loadComponent: () => import('./pages/managment/users/users.component'),
        canActivate: [authGuard],
      },
      {
        path: 'location-types',
        title: 'Location Types List',
        data: {
          pageTitle: 'Tipos de Localidades',
          permissions: ['gestor-bottletracking.acceder_gestor-bottletracking'],
        },

        loadComponent: () =>
          import(
            './pages/managment/location-types/location-types.component'
          ).then((m) => m.LocationTypesComponent),
        canActivate: [authGuard],
      },
      {
        path: 'locations',
        title: 'Location List',
        data: { pageTitle: 'Localidades' },
        loadComponent: () =>
          import('./pages/managment/locations/locations.component'),
        canActivate: [authGuard],
      },
      {
        path: 'providers',
        title: 'Providers List',
        data: { pageTitle: 'Proveedores' },
        loadComponent: () =>
          import('./pages/managment/providers/providers.component'),
        canActivate: [authGuard],
      },
      {
        path: '',
        redirectTo: 'monitor',
        pathMatch: 'full',
      },

      {
        path: 'list_users',
        title: 'Users View',
        data: { pageTitle: 'Listado de Usuarios' },
        loadComponent: () => import('./pages/users/users.component'),
        canActivate: [authGuard],
        runGuardsAndResolvers: 'always',
      },

      {
        path: 'list_warehouses',
        title: 'Warehouse View',
        data: { pageTitle: 'Listado de Comisariatos' },
        loadComponent: () => import('./pages/warehouses/warehouses.component'),
        canActivate: [authGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'list_accounts',
        title: 'Accounts View',
        data: { pageTitle: 'Listado de Cuentas' },
        loadComponent: () => import('./pages/accounts/accounts.component'),
        canActivate: [authGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'interface',
        title: 'Interface App Android',
        data: { pageTitle: 'Listado de Botones' },
        loadComponent: () => import('./pages/interface/interface.component'),
        canActivate: [authGuard],
        runGuardsAndResolvers: 'always',
      },
    ],
  },
  {
    path: 'login',
    title: 'Login',
    loadComponent: () => import('./auth/login/login.component'),
  },
  {
    path: 'no-access',
    title: 'Acceso Denegado',
    loadComponent: () => import('./pages/shared/noaccess/noaccess.component'),
  },
  {
    path: 'register',
    title: 'Register',
    data: { pageTitle: 'Register' },
    loadComponent: () =>
      import('./auth/register-wizard/register-wizard.component').then(
        (m) => m.RegisterWizardComponent
      ),
  },
  {
    path: '',
    redirectTo: '/login',
    data: { pageTitle: 'Login' },
    pathMatch: 'full',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/shared/nopagefound/nopagefound.component'),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
