<div *ngIf="modalService.getModalState('paymentModal') | async" class="modal-backdrop flex items-center justify-center">
  <div class="relative w-[500px] bg-gray-50 dark:bg-gray-900 rounded-lg shadow-lg p-6">
    <button class="absolute top-2 right-2 text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 text-2xl leading-none" (click)="modalService.closeModal('paymentModal')">&times;</button>
    <h2 class="text-sm mb-4 border-b border-gray-600 dark:border-gray-200 dark:text-white pb-2 font-semibold">Pago Requerido</h2>
    <div class="mb-4">
      <select *ngIf="overdueBills.length > 1" [(ngModel)]="selectedBillId" (ngModelChange)="updateSelectedBill()" class="custom-select w-full mb-4 p-3 bg-gray-50 dark:bg-gray-900 rounded-lg shadow-lg text-sm border-2 border-gray-400 dark:border-gray-700 text-gray-800 dark:text-gray-200 focus:ring-0 focus:border-green-400">
        <option *ngFor="let bill of overdueBills" [value]="bill.id">{{ bill.description }} - {{ bill.period }}</option>
      </select>

      <div *ngIf="selectedBill" class="mb-4 bg-gray-200 dark:bg-gray-800 p-3 shadow dark:text-white rounded-lg">
        <div *ngIf="overdueBills.length === 1" class="text-sm">{{ selectedBill.description }} - {{ selectedBill.period }}</div>
        <div class="bg-gray-300 dark:bg-gray-900 p-2 rounded mt-2">
          <div *ngFor="let detail of selectedBill.details">
            <div class="flex justify-between items-center">
              <div class="text-sm flex-1 pr-8 text-left">{{ detail.description }}</div>
              <div class="text-sm text-right">{{ detail.amount | currencyPostfix:selectedBill.currency }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mb-2">
      <svg class="fill-current text-gray-500 dark:text-gray-200" height="20" width="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 150">
        <path fill-rule="evenodd" d="M360 77.4c0 2.4-.2 7.6-.2 8.9h-48.9c1.1 11.8 9.7 15.2 19.4 15.2 9.9 0 17.7-2.1 24.5-5.5v20c-6.8 3.8-15.8 6.5-27.7 6.5-24.4 0-41.4-15.2-41.4-45.3 0-25.4 14.4-45.6 38.2-45.6 23.7 0 36.1 20.2 36.1 45.8zm-49.4-9.5h25.8c0-11.3-6.5-16-12.6-16-6.3 0-13.2 4.7-13.2 16zm-63.5-36.3c17.5 0 34 15.8 34.1 44.8 0 31.7-16.3 46.1-34.2 46.1-8.8 0-14.1-3.7-17.7-6.3l-.1 28.3-25 5.3V33.2h22l1.3 6.2c3.5-3.2 9.8-7.8 19.6-7.8zm-6 68.9c9.2 0 15.4-10 15.4-23.4 0-13.1-6.3-23.3-15.4-23.3-5.7 0-9.3 2-11.9 4.9l.1 37.1c2.4 2.6 5.9 4.7 11.8 4.7zm-71.3-74.8V5.3L194.9 0v20.3l-25.1 5.4zm0 7.6h25.1v87.5h-25.1V33.3zm-26.9 7.4c5.9-10.8 17.6-8.6 20.8-7.4v23c-3.1-1.1-13.1-2.5-19 5.2v59.3h-25V33.3h21.6l1.6 7.4zm-50-29.1l-.1 21.7h19v21.3h-19v35.5c0 14.8 15.8 10.2 19 8.9v20.3c-3.3 1.8-9.3 3.3-17.5 3.3-14.8 0-25.9-10.9-25.9-25.7l.1-80.1 24.4-5.2zM25.3 58.7c0 11.2 38.1 5.9 38.2 35.7 0 17.9-14.3 28.2-35.1 28.2-8.6 0-18-1.7-27.3-5.7V93.1c8.4 4.6 19 8 27.3 8 5.6 0 9.6-1.5 9.6-6.1 0-11.9-38-7.5-38-35.1 0-17.7 13.5-28.3 33.8-28.3 8.3 0 16.5 1.3 24.8 4.6v23.5c-7.6-4.1-17.2-6.4-24.8-6.4-5.3 0-8.5 1.5-8.5 5.4z"></path>
      </svg>
    </div>

    <div #cardInfo class="p-4 border-2 border-green-400 rounded-lg bg-gray-200 text-gray-800 dark:bg-gray-200 dark:text-gray-200"></div>
    <div *ngIf="stripeError" class="text-red-400 text-sm mt-2">{{ stripeError }}</div>
    <hr class="my-2 mt-4 border-t border-gray-600 dark:border-gray-700">

    <div *ngIf="selectedBill" class="bg-gray-200 dark:bg-gray-900 dark:text-white p-3 shadow text-sm rounded-lg">
      <div class="flex justify-between items-center">
        <div class="pr-8 text-right">Subtotal:</div>
        <div class="text-right">{{ selectedBill.subtotal | currencyPostfix:'MXN' }}</div>
      </div>
      <div class="flex justify-between items-center">
        <div class="pr-8 text-right">Impuestos:</div>
        <div class="text-right">{{ selectedBill.tax_amount | currencyPostfix:'MXN' }}</div>
      </div>
      <div class="flex justify-between items-center">
        <div class="pr-8 text-right">Total a pagar:</div>
        <div class="text-right">{{ selectedBill.amount_due | currencyPostfix:'MXN' }}</div>
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <button class="bg-green-600 hover:bg-green-700 text-white dark:text-white py-1 px-4 rounded transition duration-150 ease-in-out text-sm" (click)="handlePayment()">
        Pagar
      </button>
    </div>
  </div>
</div>
