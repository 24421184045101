import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { StripeService } from '../../services/stripe/stripe.service';
import { StripeCardElement } from '@stripe/stripe-js';
import { ModalService } from '../../services/modal/modal.service';
import { CommonModule } from '@angular/common';
import { BillingService } from '../../services/billing/billing.service';
import { Bill } from '../../models/bill.model';
import CurrencyPostfixPipe from '../../pipes/currency-postfix.pipe';
import { FormsModule } from '@angular/forms';
import { AlertService } from '../../services/alert/alert.service';
import { LoadingService } from '../../services/loading/loading.service';
import { PaymentCheckService } from '../../services/payment-check/payment-check.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styles: ``,
  standalone: true,
  imports: [CommonModule, CurrencyPostfixPipe, FormsModule],
})
export default class PaymentComponent implements OnInit {
  @ViewChild('cardInfo') cardInfo!: ElementRef;
  card!: StripeCardElement;
  overdueBills: Bill[] = [];
  selectedBillId: number | null = null; // Cambiado a number
  selectedBill: Bill | null = null;
  subTotalDue: number = 0;
  totalDue: number = 0;
  taxAmount: number = 0;
  stripeError: string | null = null;

  constructor(
    private paymentCheckService: PaymentCheckService,
    private alertService: AlertService,
    public stripeService: StripeService,
    public modalService: ModalService,
    private billingService: BillingService,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    try {
      this.loadOverdueBills();
      const elements = await this.stripeService.getElements();
      if (!elements) {
        console.error('Stripe elements could not be loaded.');
        return;
      }
      const style = {
        base: {
          color: '#32325d',
        },
      };
      this.card = elements.create('card', { style });
      this.card.mount(this.cardInfo.nativeElement);
    } catch (error) {
      console.error('Failed to load Stripe elements:', error);
    }
  }

  loadOverdueBills() {
    this.billingService.getOverdueBills().subscribe({
      next: (response) => {
        this.overdueBills = response.data;
        if (this.overdueBills.length > 0) {
          this.selectedBillId = this.overdueBills[0].id; // No conversión necesaria
          this.updateSelectedBill();
        }
        this.cdr.detectChanges();
      },
      error: (error) => console.error('Error fetching overdue bills:', error),
    });
  }

  updateSelectedBill() {
    this.selectedBill =
      this.overdueBills.find(
        (bill) => bill.id === this.selectedBillId // Comparación con número
      ) || null;
    this.calculateTotals();
  }

  calculateTotals() {
    if (!this.selectedBill) return;
    this.subTotalDue = this.selectedBill.subtotal;
    this.totalDue = this.selectedBill.amount_due;
    this.taxAmount = this.selectedBill.tax_amount;
  }

  async handlePayment() {
    this.stripeError = null;
    try {
      const { token, error } = await this.stripeService.createToken(this.card);
      if (error) {
        console.error('Stripe error:', error.message);
        this.stripeError = error.message;
        return;
      }

      if (!this.selectedBill) {
        console.error('No bill selected');
        return;
      }
      this.modalService.closeModal('paymentModal');
      this.loadingService.show();
      this.billingService
        .payBill(
          this.selectedBill.id,
          this.selectedBill.amount_due,
          this.selectedBill.currency,
          token.id
        )
        .subscribe({
          next: (response) => {
            const payment = response.data.payments?.[0];
            const transactionId = payment
              ? payment.transaction_id
              : 'desconocido';
            this.loadOverdueBills();
            this.loadingService.hide();

            this.alertService
              .showAlert(
                'Éxito',
                `Pago realizado con éxito. ID de transacción: ${transactionId}`,
                'success'
              )
              .then(() => {
                if (this.overdueBills.length > 0) {
                  this.paymentCheckService.checkPaymentStatus();
                }
              });
          },
          error: (error) => {
            console.error('Payment Failed:', error);
            this.loadingService.hide();
            this.alertService
              .showAlert(
                'Error',
                'El pago ha fallado. Por favor, inténtelo de nuevo.',
                'error'
              )
              .then(() => {
                if (this.overdueBills.length > 0) {
                  this.paymentCheckService.checkPaymentStatus();
                }
              });
          },
        });
    } catch (error) {
      this.loadingService.hide();
      console.error('Payment processing failed:', error);
      this.alertService
        .showAlert(
          'Error',
          'El pago ha fallado. Por favor, inténtelo de nuevo.',
          'error'
        )
        .then(() => {
          if (this.overdueBills.length > 0) {
            this.paymentCheckService.checkPaymentStatus();
          }
        });
    }
  }
}
